import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LINKS } from "../const";
import { Container, Footer, Flex } from "../global";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Logo from "../common/logo";
import { ADDRESS } from "../const";
import { Phone, Mail, MapPin } from "react-feather";
const FooterComponent = () => {
  const [footerData, setFooterData] = useState({});
  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/klincode/klincode_footer/main/footer.json"
    )
      .then((response) => response.json())
      .then((data) => {
        setFooterData(data);
      });
    return () => {
      // cleanup
    };
  }, []);

  const navLinks = () => (
    <NavLinksWrapper>
      {LINKS.map((item) => (
        <NavItem key={item.id}>
          <AnchorLink to={`${item.url.toLowerCase()}`}>{item.text}</AnchorLink>
        </NavItem>
      ))}
    </NavLinksWrapper>
  );
  return (
    <Footer>
      <Container>
        {navLinks()}
        <Content>
          <Address>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>

            <br />
            ul. Nowy Świat 6/12 (V piętro, klatka B)
            <br />
            00-400 Warszawa
            <br />
            <br />
            <a href={`tel:${ADDRESS.phone1}`}>
              {
                <div>
                  <Phone size={15} /> {ADDRESS.phone1}
                </div>
              }
            </a>
            <a href={`tel:${ADDRESS.phone2}`}>
              {
                <div>
                  <Phone size={15} /> {ADDRESS.phone2}
                </div>
              }
            </a>
            <a href={`mailto:${ADDRESS.mail}`}>
              {
                <div>
                  <Mail size={15} /> {ADDRESS.mail}
                </div>
              }
            </a>
          </Address>
        </Content>
      </Container>
      <Copyright>
        © created by
        <a href="https://www.klincode.pl" target='_blank'>
        www.klincode.pl
        </a>
        {new Date().getFullYear()}
        <Privacy>
          Zapoznaj się z naszą
          <AnchorLink to={"polityka-prywatnosci/#page"}>
            polityką prywatności
          </AnchorLink>
        </Privacy>
      </Copyright>
    </Footer>
  );
};

export default FooterComponent;

const Address = styled.address`
  color: ${(props) => props.theme.color.white.whiteDarker};
  text-align: left;
  font-style: normal;
  line-height: 1.5;
  a {
    color: ${(props) => props.theme.color.white.whiteDarker};
    &:hover {
      color: ${(props) => props.theme.color.white.whiteRegular};
    }
    svg {
      margin-bottom: -2px;
      margin-right: 5px;
    }
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin: 20px auto;
    text-align: center;
  }
`;

const NavLinksWrapper = styled.ul`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  text-align: center;

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 30px 0;
  }
`;

const NavItem = styled.div`
  text-transform: uppercase;
  margin-left: 10px;
  a,
  a:visited {
    color: ${(props) => props.theme.color.white.whiteDark};
    font-size: ${(props) => props.theme.font.size.xxsmall};
  }
  a:hover {
    color: ${(props) => props.theme.color.white.whiteRegular};
  }
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    font-size: ${(props) => props.theme.font.size.xsmall};
    margin: 5px 0px;
  }
`;
const Content = styled(Flex)`
  color: ${(props) => props.theme.color.white.whiteDarker};
  font-size: ${(props) => props.theme.font.size.xxsmall};
  justify-content: space-between;
  align-items: flex-start;
  p {
    color: ${(props) => props.theme.color.white.whiteDark};
    @media (max-width: ${(props) => props.theme.screen.xs}) {
      font-size: ${(props) => props.theme.font.size.xxxsmall};
    }
  }
  p:first-child {
    text-align: left;
    width: 30%;
  }
  p:last-child {
    width: 60%;
    text-align: right;
  }
`;

const Copyright = styled.div`
  text-align: center;
  color: ${(props) => props.theme.color.white.whiteDarker};
  font-size: ${(props) => props.theme.font.size.xxxsmall};
  margin-top: 30px;
  a {
    padding: 0 5px;
    color: ${(props) => props.theme.color.white.whiteRegular};
  }
`;
const Privacy = styled.div`
  color: ${(props) => props.theme.color.white.whiteDarker};
  a,
  a:visited {
    color: ${(props) => props.theme.color.white.whiteRegular};
  }
`;
const LogoWrapper = styled.div`
  a {
    color: ${(props) => props.theme.color.white.whiteRegular};
    font-weight: ${(props) => props.theme.font.weight.thin};
  }
`;
