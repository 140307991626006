export const employee = [{
        id: 1,
        name: 'Maciej',
        surname: 'Waś',
        title: 'radca prawny',
        image: '1_maciej_was',
        mail: 'maciej.was@mwdk.pl',
        team: 1,
        content: [
            'Maciej Waś specjalizuje się w prawie procesowym, w tym w prawie karnym, cywilnym i gospodarczym, oraz w arbitrażu międzynarodowym. Doradza spółkom działającym m.in. w branży transportowej, nieruchomości, energetycznej, spożywczej oraz farmaceutycznej.',
            'Doświadczenie zdobywał zarówno w największych kancelariach prawnych, a także obsługując międzynarodowe i największe krajowe podmioty gospodarcze. Doradza polskim i międzynarodowym przedsiębiorstwom w sporach sądowych i arbitrażowych.. Posiada również bogate doświadczenie w projektach inwestycyjnych, postępowaniu cywilnym, prawie handlowym oraz kompleksowej obsłudze firm oraz instytucji finansowych. Posiada duże doświadczenie także w prowadzeniu spraw cywilnych i karnych dotyczących naruszenia dóbr osobistych osób fizycznych i prawnych oraz w pełnieniu funkcji pełnomocnika w sprawach cywilnych i administracyjnych przed organami administracji publicznej. W ramach współpracy z Klientami Kancelarii odpowiada również za projekty strategiczne.',
            'Członek rady nadzorczej Centrum Nowoczesnych Technologii S.A. w której to zasiada również w komitecie audytu. ',
            'Jest autorem i współautorem licznych publikacji na temat prawa handlowego oraz postępowania cywilnego wydanych w najbardziej prestiżowych czasopismach prawniczych. ',
            'Kończy przygotowywać rozprawę doktorską z zakresu postępowania cywilnego i arbitrażowego (zakładany termin obrony: grudzień 2022).',
        ],
    },

    {
        id: 2,
        name: 'dr Daniel',
        surname: 'Krajewski',
        title: 'partner zarządzający, radca prawny',
        image: '2_daniel_krajewski',
        mail: 'daniel.krajewski@mwdk.pl',
        team: 1,
        content: [
            'Odpowiada w Kancelarii za sprawy związane z M&A oraz rynki kapitałowe. Członek rady nadzorczej największego prywatnego towarzystwa funduszy inwestycyjnych niezwiązanego z grupą bankową, Skarbiec TFI S.A., przewodniczący komitetu nominacji i wynagrodzeń oraz komitetu strategii i relacji z podmiotami rynku kapitałowego, członek komitetu audytu.',
            'Prawnik zorientowany na potrzeby i wymagania klientów biznesowych o kilkunastoletnim doświadczeniu w obsłudze podmiotów gospodarczych, tak w kraju, jak i za granicą (Nowy Jork, USA; Monachium, Niemcy).',
            'Specjalizuje się w prawie pomocy publicznej, rynku kapitałowego, prawie korporacyjnym oraz M&A. Ponadto posiada bogate doświadczenie w prawie nieruchomościowym, projektach inwestycyjnych oraz negocjacji umów, ze szczególnym uwzględnieniem projektów infrastrukturalnych. ',
            'Doświadczenie zawodowe zdobywał współpracując z renomowanymi kancelariami takimi jak Greenberg, Traurig, Grzesiak sp. k. czy RKKW- Kwaśnicki, Wróbel i Partnerzy. Przez ostatnie lata związany z Polską Grupą Zbrojeniową S.A. w której pełnił m.in. funkcję Dyrektora Departamentu Prawnego będąc odpowiedzialnym za negocjację umów o wartości wielu miliardów złotych, w tym jako szef zespołu prawnego WISŁA (offset przemysłowy związany z zakupem przez Polskę systemu rakietowego PATRIOT; główni kontrahenci: Lockheed Martin, Raytheon Company oraz Northrop Grumman reprezentowani m.in. przez brytyjskie, amerykańskie oraz polskie oddziały kancelarii White & Case, DLA Piper oraz Squire Patton Boggs).',
            'Wprowadzał na rynek główny GPW m.in. GPW, PGE Polską Grupę Energetycznę, J.W Construction Holding, Marvipol oraz ASBISc Enterprises. Nadto doradzał UniCreditS.p.A. przy przeprowadzeniu oferty publicznej i wprowadzeniu papierów wart. do obrotu na GPW, Deutsche Bórse AG (Niemcy) oraz BorsaItalianaS.p.A. (Włochy). Doradzał również CEDC przy przeprowadzeniu ofert publicznych i wprowadzeniu papierów wart. do obrotu na GPW i NASDAQ (USA) oraz przy emisjach obligacji CEDC o łącznej wartości prawie mld USD.',
            'W 2012 r. uzyskał tytuł doktora nauk prawnych. ',
            'Autor wielu publikacji naukowych z zakresu prawa korporacyjnego, nieruchomościowego, bankowego oraz prawa rynków kapitałowych, m.in. na łamach Monitora Prawniczego, Nieruchomości C.H. Beck, Monitora Prawa Handlowego oraz Miesięcznika Kapitałowego. ',
        ],
    },

    {
        id: 3,
        name: 'Anna',
        surname: 'Szymańska',
        title: 'radca prawny',
        image: '3_anna_szymanska',
        team: 2,
        content: [
            'Odpowiada w Kancelarii za obszar prawa zamówień publicznych oraz inwestycje infrastrukturalne. Doświadczenie zawodowe zdobywała zarówno w dużych kancelariach, jak również w organach administracji państwowej i spółkach Skarbu Państwa. Legitymuje się kilkunastoletnim doświadczeniem w zakresie szeroko rozumianego prawa zamówień publicznych oraz kompleksowym doradztwie na rzecz zamawiających i wykonawców dotyczącym realizacji inwestycji, w szczególności w zakresie obiektów użyteczności publicznej, obiektów objętych ochroną konserwatora zabytków i inwestycji infrastrukturalnych. Posiada bogate doświadczenie w obsłudze prawnej inwestycji realizowanych w oparciu o warunki kontraktowe FIDIC. ',
            'Specjalizuje się w prawie zamówień publicznych, kompleksowym doradztwie w zakresie realizacji projektów inwestycyjnych, procedurze cywilnej, prawie gospodarczym, prawie budowlanym, prawie cywilnym. Wielokrotnie z sukcesem reprezentowała klientów w postępowaniach przed Krajową Izbą Odwoławczą w sprawach dotyczących postępowań publicznych powyżej progów unijnych, w tym m.in. z zakresu „selfcleaningu”, rażąco niskiej ceny.  Wielokrotnie wygrywała sprawy sądowe przed sądami powszechnymi o łącznej wartości ponad kilkuset milionów złotych. Posiada bogate doświadczenie w sporządzaniu dokumentów w postępowaniach prowadzonych w reżimie ustawy Prawo zamówień publicznych, jak również w opracowywaniu oraz negocjowaniu umów inwestycyjnych, w szczególności umów których przedmiotem jest wykonanie robót budowalnych, wykonanie dokumentacji projektowej. ',
        ],
    },
    {
        id: 4,
        name: 'dr Magdalena',
        surname: 'Śliwa Wajda',
        title: 'radca prawny',
        image: '4_magdalena_sliwa_wajda',
        team: 2,
        content: [
            'Doktor nauk prawnych, adiunkt w Katedrze Prawa i Postępowania Administracyjnego na Wydziale Prawa i Administracji Uniwersytetu Warszawskiego; w roku 2011 ukończyła z wyróżnieniem (summa cum laude) jednolite, magisterskie studia dzienne na kierunku prawo na Wydziale Prawa i Administracji Uniwersytetu Warszawskiego (praca magisterska pt. „Pomoc publiczna na przykładzie przemysłu stoczniowego Polski i Niemiec”); w 2017 r. obroniła z wyróżnieniem pracę doktorską zatytułowaną "Administracyjnoprawne środki zwrotu niezgodnej z prawem pomocy państwa w Polsce i w Niemczech" (opublikowaną nakładem Instytutu Wydawniczego EuroPrawo w 2017 r.); stypendystka Uniwersytetu w Zurychu oraz Reńskiego Uniwersytetu im. Fryderyka Wilhelma w Bonn; autorka i współautorka kilkudziesięciu publikacji naukowych z zakresu prawa administracyjnego, prawa Unii Europejskiej, postępowania administracyjnego i sądowoadministracyjnego, prawa pomocy publicznej oraz prawa rynku kapitałowego; w latach 2013-2017 Kierowniczka Szkoły Prawa Niemieckiego przy Wydziale Prawa i Administracji UW; brała czynny udział w pracach nad powstawaniem Modelu kodeksu postępowania administracyjnego Unii Europejskiej w ramach grupy roboczej ReNEUAL (Research Network on EU Admistrative Law – Grupa Naukowo Badawcza ds. ',
            'Europejskiego Prawa Administracyjnego); prowadzi wykłady, ćwiczenia i warsztaty z prawa publicznego dla studentów kierunków prawniczych, administracyjnych, nauk politycznych; od 2017 r. prowadzi autorskie wykłady cykliczne pt. "Prawo pomocy publicznej dla spółek" dedykowane słuchaczom Podyplomowych Studiów Prawa Spółek na WPiA UW; jej zainteresowania naukowe i badawcze koncentrują się również wokół publicznoprawnych aspektów nadzoru nad rynkiem kapitałowym, ubezpieczeniowym oraz sektorem bankowym; prowadzi szkolenia z zakresu prawa konstytucyjnego, administracyjnego i prawa UE dla nieprawników.',
        ],
    },
    {
        id: 5,
        name: 'dr. Aleksander',
        surname: 'Hetko',
        title: 'radca prawny',
        image: '5_aleksander_hetko',
        team: 2,
        content: [
            'Radca prawny, specjalizuje się w zagadnieniach prawnych obrotu nieruchomościami. Szczególnie bliskie jego zainteresowaniom jest prawo rzeczowe w kontekście sporów powstających na tle tytułu prawnego do nieruchomości, a także ochrona własności i innych praw rzeczowych w ogólności. Prowadzi także szkolenia z prawa budowlanego, w tym z uwzględnieniem aspektów związanych z planowaniem i zagospodarowaniem przestrzennym, finansowaniem inwestycji oraz projektami infrastrukturalnymi. Specjalizuje się w zagadnieniach prawnych obrotu nieruchomościami. Bliskie jego zainteresowaniom jest prawo rzeczowe w kontekście sporów powstających na tle tytułu prawnego do nieruchomości, jak również ochrona własności i innych praw rzeczowych na styku z prawem administracyjnym. Specjalista z zakresu prawa upadłościowego oraz pomocy publicznej. ',
            'Jest m. in. autorem publikacji: „Dekret Warszawski. Wybrane aspekty systemowe” (Wydawnictwo C.H. Beck). Jego doświadczenie obejmuje pracę w międzynarodowych kancelariach, m.in.: BeitenBurkhardt, White & Case, Hogan&Hartson',
        ],
    },
    {
        id: 6,
        name: '',
        surname: '',
        title: 'radca prawny',
        image: '6_pawel_jasiński',
        team: 2,
        content: [
            'Absolwent WPiA Uniwersytetu Warszawskiego oraz MBA – Imperial College London, radca prawny od 2003 roku. Praca zawodowa: jako radca prawny oraz członek organów spółek: ASiP S.A., Studium Europejskie Sp. z o.o., PKS Nowy Sącz S.A, PKN ORLEN S.A., Unipetrol, a.s. (Praga), Instytut Edukacji Sp. z o.o., WIW sp. z o.o. sp. k., Fundacja AI Law Tech. Członek Okręgowej Rady Radców Prawnych w Warszawie 2007-2013. Wykładowca akademicki: Akademia Leona Koźmińskiego, INE PAN, Wyższa Szkoła Menadżerska. ',
            'Radca prawny od 2003 roku. Absolwent MBA – Imperial College London. Praca zawodowa: jako radca prawny oraz członek organów spółek: ASiP S.A., Studium Europejskie Sp. z o.o., PKS Nowy Sącz S.A, PKN ORLEN SA., Unipetrol, a.s. (Praga), prowadzi indywidualną praktykę radcowską, wykładowca akademicki oraz na aplikacjach prawniczych. Specjalizacja: pomoc publiczna, umowy gospodarcze, szczególnie z obszaru IT (licencyjne, wdrożeniowe, utrzymaniowe, rozwojowe, dotyczące software oraz hardware). Posiada wiedzę o różnych systemach i rozwiązaniach informatycznych m. in.: Accenture, CERA, DELL, Gartner, Horwarth, HP, IBM, Micosoft, NASK, Oracle, SAP.',
        ],
    },
    {
        id: 7,
        name: 'Janusz',
        surname: 'Werczyński',
        title: 'radca prawny',
        image: '7_janusz_werczynski',
        mail: 'janusz.werczynski@mwdk.pl',
        team: 2,
        content: [
            'Doświadczony prawnik zorientowany na potrzeby i wymagania klientów biznesowych o wieloletnim doświadczeniu. W trakcie kariery prowadził liczne procesy inwestycyjne o wartości setek mln złotych. Janusz posiada ponad 20-letnie doświadczenie w doradzaniu klientom w sprawach dotyczących nieruchomości, w tym sprzedaży i nabywania różnych aktywów nieruchomościowych, najmu powierzchni biurowych, projektów deweloperskich i partnerstwa publiczno-prywatnego. Polski polityk samorządowy, burmistrz miasta Marki w latach 1996–2014. Od 2014 przewodniczący rady powiatu wołomińskiego.',
        ],
    },
    {
        id: 8,
        name: 'Mirosław',
        surname: 'Boruta',
        title: 'radca prawny',
        image: '8_miroslaw_boruta',
        mail: 'miroslaw.boruta@mwdk.pl',
        team: 2,
        content: [
            'Odpowiada w Kancelarii za sprawy sądowe. Odpowiedzialny m.in. za obsługę Polskiej Grupy Energetycznej i spółek z grupy kapitałowej PGE (w szczególności PGE Dystrybucja) oraz zespół ds. postępowań sądowych (ok. 300 postępowań sądowych w toku). ',
            'Specjalizuje się w prawie energetycznym, prawie konsumenckim i ochronie konkurencji. Doświadczenie zawodowe zdobywał w renomowanych kancelariach prawnych, w administracji publicznej oraz w organizacjach pozarządowych. Współpracuje z działem pomocy publicznej. ',
            'Dodatkowo w Kancelarii zajmuje się sprawami z zakresu ubezpieczeń społecznych i prawa pracy. ',
        ],
    },
    {
        id: 9,
        name: 'Dagmara',
        surname: 'Gabrysiak',
        title: 'adwokat',
        image: '9_dagmara_gabrysiak',
        team: 2,
        content: [],
    },
    {
        id: 10,
        name: 'dr Marcin',
        surname: 'Kotlarz',
        title: '',
        image: '10_marcin_kotlarz',
        team: 2,
        content: [
            'Adiunkt na SGH. Absolwent Uniwersytetu Gdańskiego na wydziale Ekonomii i kierunku ekonomia.',
            'Współpracuje z Kancelarią przy najbardziej złożonych tematach od strony prawno-finansowej oraz przy wycenach podmiotów podlegających przejęciu. Problematyka badawcza dr-a Marcina Kotlarza obejmuje związki prawa z ekonomią w obszarze rynków finansowych oraz w sferze organizacji i zarządzania podmiotami rynkowymi, funkcjonowania sektora publicznego oraz wybrane aspekty ekonomii rozwoju, dotyczące tworzenia formalnych i nieformalnych instytucji rynkowych. Specjalista od funduszy unijnych i regionalnych.',
            'Stopień doktora nauk ekonomicznych w dyscyplinie ekonomia nadany w roku 2011. ',
        ],
    },
    {
        id: 11,
        name: 'Piotr',
        surname: 'Pakulski',
        title: 'adwokat',
        image: '11_piotr_pakulski',
        team: 2,
        content: [
            'Specjalizuje się w prawie cywilnym, prawie konkurencji oraz prawie własności intelektualnej, ze szczególnym uwzględnieniem tematyki kontraktów informatycznych. Prowadził około stu spraw przed sądami powszechnymi, w tym sprawy budowlane i nieruchomościowe. W Kancelarii odpowiada za prowadzenie postępowań egzekucyjnych. Posiada również doświadczenie w prowadzeniu postępowań przed Urzędem Ochrony Konkurencji i Konsumentów.',
        ],
    },
    {
        id: 12,
        name: 'Michał',
        surname: 'Wąsowicz',
        title: 'radca prawny',
        image: '12_michal_wasowicz',
        team: 2,
        content: [
            'Zajmuje się doradztwem w zakresie funkcjonowania spółek prawa handlowego na wszystkich etapach ich rozwoju, w tym zakładaniem, przekształcaniem, łączeniem i podziałem spółek oraz ich likwidacją, a także szeroko pojętym bieżącym doradztwem w działalności gospodarczej. Jego praktyka obejmuje także transakcje M&A. Jego doświadczenie obejmuje także doradztwo w zakresie transakcji na rynku nieruchomości, w tym zwłaszcza w zakresie identyfikacji ryzyk prawnych i ich ograniczania, projekty duediligence oraz przygotowywanie umów, a także doradztwo i reprezentowanie klientów w sporach sądowych.',
            'W Kancelarii odpowiada za kwestie nieruchomościowe i proces administracyjny. ',
            'Burmistrz Dzielnicy Włochy m. st. Warszawy od marca 2006 roku do listopada 2018 roku. Wcześniej z-ca burmistrza i w latach 2003 - 2005 naczelnik Wydziału Obsługi Mieszkańców. ',
        ],
    },
    {
        id: 13,
        name: 'Filip',
        surname: 'Mulik',
        title: 'prawnik',
        image: '13_filip_mulik',
        team: 2,
        content: [
            'Filip zajmuje się sporami sądowymi. Posiada również doświadczenie w obszarze pozyskiwania funduszy unijnych i rządowych, prawa ochrony środowiska , prawa karnego gospodarczego oraz prawa upadłościowego i restrukturyzacyjnego. Specjalizuje się w zagadnieniach związanych z prawem rolnym. ',
        ],
    },
    {
        id: 14,
        name: 'Piotr',
        surname: 'Waś',
        title: 'radca prawny',
        image: '14_piotr_was',
        team: 2,
        content: [
            'Prawnik posiadający ponad 25 letnie doświadczenie w obsłudze największych spółek oraz instytucji finansowych, w tym w szczególności banków. Odpowiedzialny w Kancelarii za sprawy karne i postępowanie karne posiadając unikatową wiedzę w tym zakresie wynikającą z wieloletniego pełnienia przez niego funkcji prokuratora. Zajmuje się tzw. whitecollarcrimes, w tym piramidami finansowymi oraz „nieprawidłowościami” w zakresie podatków VAT, kontaktami z sądami oraz prokuratorami, w tym Prokuraturą Krajową. Posiada liczne sukcesy w reprezentowaniu klientów w sprawach karnych, m.in. uzyskując liczne wyroki uniewinniające lub warunkowe umorzenie postępowania. Zajmuje się również postępowaniem administracyjnym i kwestiami związanymi z pozyskiwaniem wszelkiego rodzaju zezwoleń i pozwoleń administracyjno-prawnych.',
        ],
    },
    {
        id: 15,
        name: 'Paweł',
        surname: ' Bujalski',
        title: 'radca prawny',
        image: '15_pawel_bujalski',
        team: 'inactive',
        content: [
            'Odpowiada w Kancelarii za kwestie związane z rynkiem reklamowym. Doświadczony prawnik biznesowy, posiadający wieloletnie doświadczenie w branży kulturalnej, nieruchomości oraz w obsłudze prawnej spółek. Specjalista z zakresu szeroko pojętej kultury, sztuki i reklamy. Posiada szerokie doświadczenie w zakresie prawa autorskiego obsługując podmioty z branży artystycznej.',
        ],
    },
    {
        id: 16,
        name: '',
        surname: ' ',
        title: '',
        image: '',
        team: 2,
        content: [],
    },
    {
        id: 17,
        name: 'Zenon',
        surname: ' Kotlarz',
        title: 'radca prawny',
        image: '17_zenon_kotlarz',
        team: 2,
        content: [
            'Specjalizuje się w postępowaniach karnych oraz sprawach z zakresu prawa pracy. Zajmuje się również bieżącą obsługą podmiotów gospodarczych, w szczególności w obszarze prawa pracy i zwolnień grupowych. Ponadto prowadzi z sukcesami liczne postępowania sądowe na gruncie prawa karnego oraz prawa pracy.',
        ],
    },
    {
        id: 18,
        name: 'Dr Olga',
        surname: 'Słabońska',
        title: 'tłumacz',
        image: '18_olga_slabonska',
        team: 2,
        content: [
            'Doktor nauk humanistycznych w dyscyplinie językoznawstwo. Pracownik Naukowy Uniwersytetu Humanistyczno-Przyrodniczy im. Jana Długosza w Częstochowie. Z Kancelarią współpracuje w zakresie wykonywania tłumaczeń dokumentacji. ',
        ],
    },
    {
        id: 19,
        name: 'Barbara',
        surname: 'Andershon - Jackiewicz',
        title: 'office manager',
        image: '19_barbara_andershon_jackiewicz',
        team: 2,
        content: [
            'Zajmuje się PR-em oraz działaniami marketingowymi. Koordynuje komunikację zewnętrzną Kancelarii oraz przygotowuje i wdraża strategie wizerunkowe. Posiada wieloletnie doświadczenie związane z digital marketingiem oraz budowaniem wizerunku marki w Internecie i mediach społecznościowych. Dba o prawidłowe funkcjonowanie Biura Kancelarii. ',
        ],
    },
    {
        id: 20,
        name: 'Wiktoria',
        surname: 'Krajnik',
        title: 'asystent Kancelarii',
        image: '20_wiktoria_krajnik',
        team: 2,
        content: [
            'Współpracuje z zespołem prawa procesowego, zespołem prawa konkurencji oraz prawa nieruchomościowego. W Kancelarii zajmuje się również biblioteką oraz kontaktem z organami administracji publicznej. Zapewnia sprawny obieg dokumentacji wewnątrz Kancelarii.',
        ],
    },
    {
        id: 21,
        name: 'Izabela',
        surname: 'Skubel',
        title: 'adwokat',
        image: '21_izabela_skubel',
        team: 'inactive',
        content: [
            'Adwokat, specjalizuje się w prawie cywilnym, gospodarczym, pracy oraz w prawie procesowym.  ',
            'Posiada bogate doświadczenie w prawnoustrojowej i korporacyjnej obsłudze spółek prawa handlowego, w szczególności w opracowywaniu, opiniowaniu i negocjowaniu umów, w sporządzaniu wewnętrznych regulacji dotyczących działalności spółek, w przygotowywaniu dokumentów korporacyjnych oraz w zakresie tworzenia, rejestrowania, przekształcania, fuzji i przejęć spółek prawa handlowego. ',
            'Posiada także szerokie doświadczenie w bieżącej obsłudze prawnej z zakresu prawa pracy m.in w opracowywaniu dokumentów pracowniczych oraz ​wewnętrznych pracowniczych aktów prawnych, w przeprowadzaniu przejęć pracowników i likwidacji zakładu pracy, zwolnień grupowych i sporów zbiorowych.',
            'W swojej praktyce zajmuje się również prowadzeniem spraw sądowych wynikających z prowadzenia działalności gospodarczej oraz sporów konsumenckich.',
        ],
    },
    {
        id: 22,
        name: 'Aneta',
        surname: 'Kurowska-Siuda',
        title: 'radca prawny',
        image: '22_aneta_kurowska_siuda',
        team: 'inactive',
        content: [
            'Radca prawny specjalizujący się w kwestiach regulacyjnych, prawie handlowym, prawie administracyjnym oraz procesie cywilnym. Przez ponad 5 lat była kierownikiem ds. korporacyjnych w wiodącej spółce produkującej wyroby tytoniowe. ',
            'W zakresie jej obowiązków było tworzenie polityki korporacyjnej i medialnej spółki oraz reprezentowanie jej na zewnątrz. Jest absolwentką Wydziału Prawa i Administracji Uniwersytetu Warszawskiego w Warszawie oraz studiów podyplomowych z zakresu prawa europejskiego Europejskiej Wyższej Szkoły Prawa i Administracji w Warszawie.  W 2010 roku zdobyła uprawnienia radcy prawnego.',
        ],
    },
    {
        id: 23,
        name: 'Magdalena',
        surname: 'Jaskot',
        title: 'adwokat',
        image: '23_magdalena_jaskot',
        team: 2,
        content: [
            'Adwokat specjalizujący się w prawie energetycznym, administracyjnym w tym w szczególności w zakresie regulacji dot. służb mundurowych, prawie pracy oraz procesie cywilnym. ',
            'W Kancelarii świadczy usługi na rzecz spółek energetycznych obsługując procesy sądowe oraz kwestie infrastrukturalne. Wspiera również zespół regulacyjny. Z sukcesami reprezentowała klientów Kancelarii przed NSA i SN. Ponadto posiada bogate doświadczenia z zakresu obsługi prawnej związków zawodowych',
        ],
    },
    {
        id: 24,
        name: 'Jacek',
        surname: 'Jaruchowski',
        title: 'adwokat',
        image: '24_jacek_jaruchowski',
        team: 2,
        content: [
            'Absolwent Wydziału Prawa i Administracji Uniwersytetu Wrocławskiego (praca magisterska: Instytucja świadka koronnego w polskiej procedurze karnej). Radca prawny od 2013 roku (Okręgowa Izba Radców Prawnych we Wrocławiu), zaś od 2017 roku – adwokat (Izba Adwokacka w Warszawie).',
            'W 2014 roku ukończył Podyplomowe Studia Prawa Medycznego i Bioetyki współorganizowane przez Wydział Prawa, Administracji i Ekonomii Uniwersytetu Wrocławskiego oraz Wydział Lekarski Kształcenia Podyplomowego Uniwersytetu Medycznego we Wrocławiu.',
            'Doświadczenie zdobywał w renomowanych kancelariach prawnych oraz jako prawnik wewnętrzny. Specjalizuje się w prawie procesowym – tak cywilnym, jak i karnym – oraz w prawie kontraktowym i medycznym. Posiada wieloletnie doświadczenie we wspieraniu przedsiębiorców m.in. z branży deweloperskiej oraz spożywczej (w zakresie inwestycji) i transportowej. ',
            'Posiada wieloletnie doświadczenie w prowadzeniu sporów sądowych.',
            'Wielokrotnie występował jako obrońca oraz jako pełnomocnik pokrzywdzonych w sprawach związanych z piramidami finansowymi, praniem brudnych pieniędzy czy działalnością zorganizowanych grup przestępczych, sprawach z zakresu zwalczania nieuczciwej konkurencji oraz zniesławień za pomocą środków masowego komunikowania się. Oprócz tego wielokrotnie reprezentował oskarżonych także w „zwykłych” sprawach kryminalnych.',
            'W obszarze prawa cywilnego procesowego występował w sądach jako pełnomocnik w sprawach dotyczących inwestycji budowlanych, prawa pracy, prawa gospodarczego czy prawa spółek handlowych oraz w sprawach o zapłatę w związku z niewykonaniem lub nienależytym wykonaniem umów.',
            'Jest autorem szeregu publikacji. Jego artykuł dotyczący problematyki zawarcia umowy o pracę z naruszeniem art. 210 k.s.h. był przedmiotem cytowań w orzecznictwie Sądu Najwyższego, sądów okręgowych oraz jednego z wojewódzkich sądów administracyjnych.',
            'Zasiadał w radach nadzorczych spółek prawa handlowego.',
            'Oprócz pracy zawodowej realizuje się także jako wolontariusz i przewodnik w Muzeum Powstania Warszawskiego oraz jako wolontariusz w Muzeum Historii Żydów Polskich POLIN.',
        ],
    },
    {
        id: 25,
        name: 'Magdalena',
        surname: 'Szymko',
        title: 'Radca prawny ',
        image: '25_magdalena_szymko',
        team: 2,
        content: [
            'Radca prawny, specjalizuje się w zagadnieniach prawnych dotyczących inwestycji budowlanych, prawie energetycznym, z uwzględnieniem m.in. OZE i przesyłu, z sukcesem reprezentowała klientów w postępowaniach przed Krajową Izbą Odwoławczą oraz sądami powszechnymi.',
        ],
    },
    {
        id: 26,
        name: 'Igor',
        surname: 'Juzwa',
        title: 'Radca prawny',
        image: '26_igor_juzwa',
        team: 2,
        content: [
            'Jest doświadczonym prawnikiem z długoletnim stażem. Po ukończeniu studiów i po odbyciu aplikacji sędziowskiej od 2002 roku orzekał w Sądzie Gospodarczym w Warszawie, był i jest związany z renomowaną warszawską Kancelarią Prawniczą i licznymi dużymi Podmiotami Gospodarczymi, gdzie specjalizuję się w prawie gospodarczym, cywilnym, handlowym i korporacyjnym. W tym zakresie specjalizował się w dokonywaniu wielu fuzji, przejęć, połączeń podmiotów gospodarczych. Prowadzi również sprawy procesowe.',
        ],
    },
    {
        id: 27,
        name: 'Martyna',
        surname: 'Bodych',
        title: 'Adwokat',
        image: '27_martyna_bodych',
        team: 2,
        content: [
            'Zawód adwokata wykonuje od 2017 roku. Posiada wiedzę i doświadczenie procesowe w prowadzeniu spraw z zakresu prawa:',
            '•	cywilnego (np. o ustanowienie służebności przesyłu, odszkodowania za szkody komunikacyjne), ',
            '•	rodzinnego (rozwód, podział majątku wspólnego, ustanowienie rozdzielności małżeńskiej, alimenty, ustalanie kontaktów)',
            '•	oraz karnego. ',
            'Posiada również doświadczenie w obsłudze prawnej spółdzielni oraz spółek prawa handlowego, a od 2019r.  zajmuje się prowadzeniem spraw tzw. frankowych.',
        ],
    },
    {
        id: 28,
        name: 'Agnieszka',
        surname: 'Witkowska',
        title: 'Asystentka kancelarii',
        image: '28_agnieszka_witkowska',
        team: 2,
        content: [
            'Wspiera zespół w organizacji oraz realizacji zadań z zakresu kredytów frankowych. W kancelarii zajmuje się pracami administracyjnymi związanymi z obiegiem dokumentów i korespondencji, jak również dba o jej właściwą archiwizację. Kontaktuje się z instytucjami m.in. sądami i urzędami.',
        ],
    },
]

export const LINKS = [{
        id: 1,
        text: 'home',
        url: '/#top',
    },
    {
        id: 2,
        text: 'o kancelarii',
        url: '/#about',
    },
    {
        id: 3,
        text: 'zespół',
        url: '/#team',
    },
    {
        id: 4,
        text: 'specjalizacje',
        url: '/#services',
    },
    //{
    //   id: 5,
    //    text: 'referencje',
    //    url: '/#references',
    //},
    {
        id: 6,
        text: 'kariera',
        url: '/#career',
    },
    {
        id: 7,
        text: 'kontakt',
        url: '/#contact',
    },
]

export const ADDRESS = {
    phone1: '+ 48 575 668 076',
    phone2: '+ 48 515 224 073',
    mail: 'kancelaria@mwdk.pl',
    address: 'ul. Nowy Świat 6/12 (V piętro, klatka B)',
    address2: ' 00-400 Warszawa',
    www: ' http://www.adresstrony.pl',
    nazwa_podmiotu: 'MWDK Waś, Krajewski & Wspólnicy',
    nip: ' 0000000000',
}

export const LOGOS = [{
        title: 'PGE',
        url: 'https://www.gkpge.pl/Oferta',
        img: 'pge.png',
    },
    {
        title: 'PLL LOT',
        url: 'https://www.lot.com/pl/pl/',
        img: 'lot.png',
    },
    {
        title: 'SUEZ Polska oraz wszystkie spółki z grupy kapitałowej SUEZ ',
        url: 'https://www.suez.pl/pl-pl',
        img: 'suez.png',
    },
    {
        title: 'ARCADIS ',
        url: 'https://www.arcadis.com/en?sc_lang=en ',
        img: 'arcadis.jpg',
    },
    {
        title: 'MKL-Bud',
        url: 'http://mklbud.nazwa.pl/wp/?page_id=33&lang=pl ',
        img: 'mkl.png',
    },
    {
        title: 'phdoctor',
        url: 'https://phdoctorlab.pl/',
        img: 'doctor.webp',
    },
    {
        title: 'rekvar',
        url: 'http://www.rekwar.com.pl/',
        img: 'rekvar.png',
    },
    {
        title: 'Pitradwar',
        url: 'https://www.pitradwar.com',
        img: 'pitradwar.png',
    },
    {
        title: 'POLSKI INSTYTUT SZTUKI FILMOWEJ ',
        url: 'https://pisf.pl/#',
        img: 'pisf.png',
    },

    {
        title: 'CENTRUM GIEŁDOWE ',
        url: 'http://www.cgsa.com.pl/ ',
        img: 'cgsa.jpg',
    },
    {
        title: 'URZĄD TRANSPORTU KOLEJOWEGO',
        url: 'https://www.utk.gov.pl/ ',
        img: 'utk.png',
    },
    {
        title: 'POLREGIO',
        url: 'https://polregio.pl/pl/ ',
        img: 'polregio.jpg',
    },
    {
        title: 'KRAJOWY DEPOZYT PAPIERÓW WARTOŚCIOWYCH',
        url: 'http://www.kdpw.pl/pl/Strony/Home.aspx ',
        img: 'kdpw.jpg',
    },
    {
        title: 'CS NATURA TOUR',
        url: 'https://www.naturatour.pl/ ',
        img: 'natura.png',
    },
    {
        title: 'KSSE',
        url: 'https://www.ksse.com.pl/o-ksse-1 ',
        img: 'ksse.svg',
    },
    {
        title: 'AQUA RD',
        url: 'https://www.aquard.pl/ ',
        img: 'aqua.png',
    },
    // {
    //   title: "VIEW CONCEPT",
    //   url: "#",
    //   img: "",
    // },
    {
        title: 'CENTRUM BANKOWO- FINANSOWE Nowy Swiat SA',
        url: 'https://www.cbf.com.pl/ ',
        img: 'mars.jpg',
    },

    {
        title: 'Wodny Park',
        url: 'https://www.wodnypark.com.pl/',
        img: 'wodnypark.png',
    },
    {
        title: 'Archidiecezja',
        url: 'https://archidiecezja.warszawa.pl/',
        img: 'archidiecezja.png',
    },
    {
        title: 'DlaTransplantacji',
        url: 'https://dlatransplantacji.pl/',
        img: 'fundacja_transplantacji.png',
    },
    {
        title: 'kontynenty',
        url: 'https://magazynkontynenty.pl/fundacja/',
        img: 'kontynenty.png',
    },
    {
        title: 'Roma',
        url: 'https://www.romaoc.pl/index.php/pl/',
        img: 'roma.png',
    },
    {
        title: 'FundacjaES',
        url: 'http://fundacjaes.pl/',
        img: 'fundacjaes.png',
    },
    {
        title: 'Con Serwis',
        url: 'http://conserwis.pl/',
        img: 'conserwis.png',
    },
    {
        title: 'Pol',
        url: 'https://pol.org.pl/',
        img: 'polakom.png',
    },
    {
        title: 'KidsView',
        url: 'https://kidsview.pl/o-nas/',
        img: 'kidsview.png',
    },
    {
        title: 'Anders',
        url: 'http://anders.org.pl/',
        img: 'anders.jpg',
    },
    {
        title: 'Nerlo',
        url: 'http://www.nerlo.pl/',
        img: 'nerlo.png',
    },
    {
        title: 'Esculap',
        url: 'https://www.esculap.com/',
        img: 'esculap.png',
    },
]

export const SPECIALISATIONS = [{
        title: 'Fundacje rodzinne',
        description: 'Kancelaria świadczy kompleksową pomoc prawną, związaną z zakładaniem i prowadzeniem nowej w polskim prawie instytucji – fundacji rodzinnej, w tym także pod kątem doradztwa podatkowego. Oferujemy fachowe usługi',
        icon: 'fundacjeRodzinne.jpg',
        url: 'fundacje-rodzinne',
        size: 'small',
    }, ,
    {
        title: 'Postępowania sądowe i administracyjne',
        description: '',
        icon: 'sadAdministracja.jpg',
        // url: 'postepowanie-sadowe-i-administracyjne',
        size: 'small',
    },
    {
        title: 'Prawo spółek',
        description: 'Kompleksowa obsługa spółek na każdym jej etapie, a zatem od powstania, funkcjonowania, aż do ustania bytu prawnego',
        icon: 'prawoSpolek.jpg',
        url: 'prawo-spolek',
        size: 'small',
    },
    {
        title: 'Pomoc publiczna',
        description: 'Pomoc publiczna',
        icon: 'pomocPubliczna.jpg',
        // url: 'pomoc-publiczna',
        size: 'small',
    },
    {
        title: 'Fuzje i przejęcia',
        description: 'Kompleksowa pomoc prawna w przeprowadzaniu procesu połączenia, podziału lub przekształcenia spółki ',
        icon: 'fuzjePrzejecia.jpg',
        url: 'fuzje-i-przejecia',
        size: 'small',
    },
    {
        title: 'Rynek Kapitałowy',
        description: 'Fachowa pomoc prawna związana z funkcjonowaniem szeroko pojętego rynku kapitałowego. ',
        icon: 'rynekKapitalowy.jpg',
        url: 'rynek-kapitalowy',
        size: 'small',
    },
    {
        title: 'Prawo kolejowe',
        description: 'Jedną z unikatowych specjalizacji Kancelarii jest także doradztwo spółkom kolejowym w zakresie obowiązujących je regulacji, tak na gruncie krajowym, jak i europejskim. ',
        icon: 'prawoKolejowe.jpg',
        url: 'prawo-kolejowe',
        size: 'small',
    },

    {
        title: 'Nieruchomości i prawo budowlane',
        description: '',
        icon: 'nieruchomosci.jpg',
        // url: 'nieruchomisci-prawo-budowlane',
        size: 'small',
    },
    {
        title: 'Umowy - kontrakty',
        description: '',
        icon: 'umowyKontrakty.jpg',
        // url: 'umowy-kontrakty',
        size: 'small',
    },
    {
        title: 'Prawo pracy',
        description: '',
        icon: 'prawoPracy.jpg',
        // url: 'prawo-pracy',
        size: 'small',
    },
    {
        title: 'Prawo zamówień publicznych',
        description: '',
        icon: 'prawoZamowienPublicznych.jpg',
        // url: 'prawo-zamowien-publicznych',
        size: 'small',
    },
    {
        title: 'Energetyka i infrastruktura',
        description: '',
        icon: 'energetyka.jpg',
        // url: 'energetyka',
        size: 'small',
    },

    {
        title: 'Podatki',
        description: '',
        icon: 'podatki.jpg',
        // url: 'podatki',
        size: 'small',
    },
    {
        title: 'Prawo karne (w tym tzw. white-collar crimes)',
        description: '',
        icon: 'prawoKarne.jpg',
        // url: 'podatki',
        size: 'small',
    },
    {
        title: 'prawo zbrojeniowe',
        description: '',
        icon: 'army.jpg',
        // url: 'prawo-zbrojeniowe',
        size: 'small',
    },
    {
        title: 'Prawo lotnicze',
        description: '',
        icon: 'prawoLotnicze.jpg',
        // url: 'pawo-lotnicze',
        size: 'small',
    },
    {
        title: 'Własność intelektualna',
        description: '',
        icon: 'wlasnoscIntelektualna.jpg',
        // url: 'wlasnosc-intelektualna',
        size: 'small',
    },
    // {
    // 	title: 'tzw. kredyty frankowe',
    // 	description: 'Kancelaria prowadzi sprawy umów kredytów powiązanych z walutami obcymi, w szczególności z CHF, USD, EUR oraz JPY. ',
    // 	icon: 'kredytyFrankowe.jpg',
    // 	url: 'kredyty-frankowe',
    // 	size: 'small',
    // },
]