import styled, { css } from 'styled-components'

export const Button = styled.button(
	(props) => `
  display:block;
  border: 1px solid transparent;
  outline: none;
  padding: 12px 30px;
  line-height:;
  cursor: pointer;
  border-radius: 50px;
  text-transform:uppercase;
  font-size:${props.theme.font.size.xxxsmall};
  font-weight:${props.theme.font.weight.medium};



  ${
		props.primary &&
		`
    background-color:${props.theme.color.black.grey};
    color:${props.theme.color.white.whiteRegular};

  `
	};

${
	props.secondary &&
	`
  border-radius: 0px;
    background-color:${props.theme.color.white.whiteRegular};
    color:${props.theme.color.black.grey};
    border:1px solid ${props.theme.color.white.whiteDarker};
    font-size:${props.theme.font.size.xxsmall};
    letter-spacing:1px;
    transition:${props.theme.transition.primary};

    &:hover {
      background-color:${props.theme.color.black.greyDarker};
      color:${props.theme.color.white.whiteDark};
    }

  `
};
${
	props.accent &&
	`
  background-color:${props.theme.color.accent};
  color:${props.theme.color.white.whiteRegular};
  `
};
${
	props.accent2 &&
	`
  padding: 8px 30px;
  background-color:${props.theme.color.accent};
  color:${props.theme.color.white.whiteRegular};
  border:1px solid ${props.theme.color.white.whiteRegular};
  `
};

${
	props.small &&
	`
  padding: 3px 10px;
  font-size:${props.theme.font.size.xxxxsmall};
  position:absolute;
  right:5px;

`
};

`
)

export const Container = styled.div`
	max-width: 1400px;
	margin: 0 auto;
	padding: 0 15px;
	z-index: 1;
	overflow: hidden;

	@media (max-width: ${(props) => props.theme.screen.xs}) {
		/* padding: 0 5px; */
	}
`

export const Section = styled.section`
	position: relative;
	padding: 50px 0px;
	overflow: hidden;
	background-color: ${(props) => props.theme.color.background};
	@media (max-width: ${(props) => props.theme.screen.xs}) {
		padding: 30px 0px;
	}
`

export const Aside = styled.aside`
	padding: 0px 0px 55px;

	overflow: hidden;
	background-color: ${(props) => props.theme.color.white.whiteRegular};
	color: ${(props) => props.theme.color.primary};

	@media (max-width: ${(props) => props.theme.screen.xs}) {
		padding: 10px 0px;
	}
`

export const Footer = styled.footer`
	padding: 20px 0px;
	overflow: hidden;
	background-color: ${(props) => props.theme.color.backgroundDarker};
	a,
	a:visited {
		color: ${(props) => props.theme.color.white.whiteRegular};
	}
	@media (max-width: ${(props) => props.theme.screen.xs}) {
		padding: 10px 0px;
		ul {
			flex-direction: column;
		}
	}
`

export const Line = styled.hr`
	border: none;
	height: 1px;
	background-color: ${(props) => props.theme.color.white.transparentLight};
`

export const Flex = styled.div`
	display: flex;
	${(props) => (props.column ? 'flex-direction:column' : 'flex-direction:row')}
	justify-content: center;
	align-items: center;
`

export const Button24h = styled(Button)`
	width: 400px;
	margin-top: 10px;
	margin-bottom: 0px;
	padding: 5px 5px;
	background: ${(props) => (props.scrolled ? props.theme.color.accent : props.theme.color.white.whiteRegular)};

	color: ${(props) => (props.scrolled ? props.theme.color.white.whiteRegular : props.theme.color.accent)};

	@media (max-width: ${(props) => props.theme.screen.sm}) {
		display: none;
	}
`

export const Dot = styled.li`
	line-height: 1.1;

	&::before {
		content: '⦿';
		padding-right: 8px;
		color: ${(props) => props.theme.color.accent};
	}
`
