import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import theme from '../../styles/theme'
import GlobalStyles from '../../styles/GlobalStyles'
import Overlay from '../common/overlay'
import Footer from '../footer/footer'
const Layout = ({ children }) => {
	// useEffect(() => {
	// 	const gatsbyContainer = document.querySelector('html')
	// 	gatsbyContainer.style.opacity = '0'
	// 	gatsbyContainer.style.transition = 'opacity 0.3s'
	// 	const showPageTimer = setTimeout(() => {
	// 		gatsbyContainer.style.opacity = '1'
	// 	}, 1000)
	// }, [])
	return (
		<>
			<ThemeProvider theme={theme}>
				<GlobalStyles />
				{/* <Overlay /> */}
				{children}
				<Footer />
			</ThemeProvider>
		</>
	)
}

export default Layout
