import React, { Component } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Scrollspy from "react-scrollspy";
import { Menu, X } from "react-feather";
import styled from "styled-components";
import { Container, Button, Button24h } from "../global";
import { LINKS, ADDRESS } from "../const";
import Logo from "../common/logo";
class Navigation extends Component {
  state = {
    mobileMenuIsOpen: false,
    hasScrolled: false,
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 200) {
      this.setState({ hasScrolled: true });
    } else {
      this.setState({ hasScrolled: false });
    }
  };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuIsOpen: !prevState.mobileMenuIsOpen,
    }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuIsOpen) {
      this.setState({ mobileMenuIsOpen: false });
    }
  };

  navLinks = ({ mobile = false }) => (
    <NavLinksWrapper mobile={mobile}>
      <Scrollspy
        items={LINKS.map((item) => item.url.toLowerCase())}
        mobile={mobile}
        offset={-64}>
        {LINKS.map((item) => (
          <NavItem
            key={item.id}
            onClick={this.closeMobileMenu}
            scrolled={this.state.hasScrolled}>
            <AnchorLink to={`${item.url.toLowerCase()}`}>
              {item.text}
            </AnchorLink>
          </NavItem>
        ))}
      </Scrollspy>
    </NavLinksWrapper>
  );

  render() {
    const { mobileMenuIsOpen } = this.state;

    return (
      <Nav
        scrolled={this.state.hasScrolled}
        isOpen={this.state.mobileMenuIsOpen}>
        <Flex>
          <LogoWrapper
            scrolled={this.state.hasScrolled}
            isOpen={this.state.mobileMenuIsOpen}
            click={this.closeMobileMenu}>
            <Logo />
          </LogoWrapper>
          <Column>
            <a href={`tel:${ADDRESS.phone1}`}>
              <Button24h type='phone' scrolled={this.state.hasScrolled}>
                24h numer alarmowy w sprawach pilnych :
                {<span> {ADDRESS.phone1}</span>}
                {/* <span> +48 575 668 076</span> */}
              </Button24h>
            </a>
            {/* menu mobilne  */}
            <Mobile
              scrolled={this.state.hasScrolled}
              isOpen={this.state.mobileMenuIsOpen}>
              <button onClick={this.toggleMobileMenu}>
                {this.state.mobileMenuIsOpen ? (
                  <X size={24} alt='close menu' />
                ) : (
                  <Menu size={24} alt='open menu' />
                )}
              </button>
            </Mobile>

            {/* hide = ukryj menu mobilne */}
            <Mobile hide>{this.navLinks({})}</Mobile>
          </Column>
        </Flex>

        <Mobile isOpen={this.state.mobileMenuIsOpen}>
          {mobileMenuIsOpen && (
            <MobileMenu scrolled={this.state.hasScrolled}>
              <Container>{this.navLinks({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}

export default Navigation;

const Nav = styled.nav`
  /* backdrop-filter: blur(6px); */
  padding: ${(props) => (props.scrolled ? `10px 0` : `0px 0`)};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 1px 0px 15px -4px ${(props) => props.theme.color.black.transparent};
  background: ${(props) =>
    props.scrolled
      ? props.theme.color.white.transparent
      : props.theme.color.black.transparent};
  transition: ${(props) => props.theme.transition.secondary};

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    background: ${(props) =>
      props.isOpen ? props.theme.color.white.transparent : null};
    padding: ${(props) => (props.scrolled ? `0px 0` : `0px 0`)};
    /* background-color: rgba(255, 255, 255, 0.95); */
  }
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    padding: 0;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;
const Flex = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => props.theme.other.navigationHeight};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    height: 70px;
  }
`;

const Mobile = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    overflow: hidden;
    /* background-image: url("../../images/baner.jpg"); */
  }
  ${(props) =>
    props.hide &&
    `
    display: block;
    @media (max-width: ${props.theme.screen.sm}) {
      display: none;
    }
    `}

  button {
    background-color: transparent;
    color: ${(props) =>
      props.scrolled
        ? props.theme.color.black.grey
        : props.theme.color.white.whiteRegular};
    @media (max-width: ${(props) => props.theme.screen.md}) {
      color: ${(props) =>
        props.isOpen
          ? props.theme.color.black.grey
          : props.theme.color.black.white};
    }
  }
`;

const MobileMenu = styled.div`
  top: 0px;
  display: flex;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: ${(props) =>
    props.scrolled ? "rgba(255,255,255,0.5)" : "rgba(255,255,255,0.1)"};
`;
const NavLinksWrapper = styled.div`
  ul {
    display: flex;
    flex-direction: row;
    ${({ mobile }) =>
      mobile &&
      `
    flex-direction: column;
    margin-top: 4em;
    > ${NavItem} {
      margin: 0;
        }
        `};
  }
`;

const NavItem = styled.li`
  margin-left: 0.75em;
  a,
  a:visited {
    position: relative;
    margin-left: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: ${(props) => props.theme.font.weight.regular};
    font-size: ${(props) => props.theme.font.size.xxsmall};
    padding: 20px 0px;
    color: ${(props) =>
      props.scrolled
        ? props.theme.color.black.grey
        : props.theme.color.white.whiteRegular};

    :before {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 5px;
      border-radius: 1px;
      background-color: ${(props) => props.theme.color.white.whiteRegular};
      opacity: 0;
      transition: ${(props) => props.theme.transition.primary};

      background-color: ${(props) =>
        props.scrolled
          ? props.theme.color.accent
          : props.theme.color.white.whiteRegular};

      @media (max-width: ${(props) => props.theme.screen.sm}) {
        height: 20px;
        width: 5px;
        bottom: 20px;
        left: -10px;
      }
    }

    @media (max-width: ${(props) => props.theme.screen.sm}) {
      font-size: ${(props) => props.theme.font.size.regular};
      min-width: 120px;
      color: ${(props) => props.theme.color.black.grey};
    }
  }

  a:hover::before {
    opacity: 0.7;
  }
`;

const LogoWrapper = styled.div`
  margin: 0;
  a,
  a:visited {
    color: ${(props) =>
      props.scrolled
        ? props.theme.color.black.grey
        : props.theme.color.white.whiteRegular};

    @media (max-width: ${(props) => props.theme.screen.md}) {
      color: ${(props) =>
        props.isOpen
          ? props.theme.color.black.grey
          : props.theme.color.black.white};
    }
  }
`;
