import React from "react";
import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";
const Logo = ({ scrolled, click }) => {
  return (
    <>
      <AnchorLink to='/#top' onClick={() => click()}>
        <Initials>MWDK</Initials>
        <FullNames>
          Waś,
          <br /> Krajewski <br />& Wspólnicy
        </FullNames>
      </AnchorLink>
    </>
  );
};

export default Logo;

const Initials = styled.span`
  font-size: 33px;
  position: relative;
  padding: 0;
  margin: 0;
  &::after {
    content: "/";
    display: block;
    padding: 0;
    line-height: 1.15;
    font-size: 33px;
    margin: 0;
    position: absolute;
    right: -15px;
    top: 0;
    /* height: "20px"; */
  }
`;

const FullNames = styled.div`
  display: inline-block;

  /* width: 50px; */
  text-align: left;
  font-size: ${(props) => props.theme.font.size.xxxxsmall};
  line-height: 1;
  margin-left: 25px;
  letter-spacing: 2px;
  font-weight: ${(props) => props.theme.font.weight.Light};
`;
