import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Container, Line, Flex, Button24h } from '../global'
import baner from '../../images/baner.jpg'
import { isIE } from 'react-device-detect'
import { ADDRESS, SPECIALISATIONS } from '../const'
import { Phone, Mail } from 'react-feather'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import BackgroundSlider from 'gatsby-image-background-slider'
import { useStaticQuery } from 'gatsby'
import gsap, { Power2 } from 'gsap'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { graphql } from 'gatsby'
//import Img from "gatsby-image";
const Header = () => {
	const slideDurationTime = 5

	let slideItem1 = useRef(null)
	let slideItem2 = useRef(null)
	let slideItem3 = useRef(null)
	let slideItem4 = useRef(null)
	let slideItem5 = useRef(null)
	let svgbottom = useRef(null)
	let bottomElements = useRef(null)
	let svgbg = useRef(null)
	useEffect(() => {
		let animation = gsap.timeline()
		gsap.fromTo(bottomElements, { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 1, ease: Power2.easeInOut })
		gsap.fromTo(svgbg, { opacity: 0, x: -50 }, { opacity: 1, x: 0, duration: 4, ease: Power2.easeInOut, delay: 1.5 })
		// gsap.fromTo(
		//   svgbottom,
		//   { opacity: 0, y: 20 },
		//   { opacity: 1, y: 0, duration: 0.1, delay: 1 }
		// );
		animation
			.fromTo(slideItem1, { y: -60, opacity: 0 }, { y: 0, duration: 3, opacity: 1, ease: Power2.easeInOut })
			.to(slideItem1, { opacity: 1, duration: 5 })
			.fromTo(slideItem2, { y: -60, opacity: 0, opacity: 0 }, { y: 0, duration: 3, opacity: 1, ease: Power2.easeInOut })
			.to(slideItem2, { opacity: 1, duration: 5 })
			.fromTo(slideItem3, { y: -60, opacity: 0 }, { y: 0, duration: 3, opacity: 1, ease: Power2.easeInOut })
			.to(slideItem3, { opacity: 1, duration: 5 })
			.fromTo(slideItem4, { y: -60, opacity: 0 }, { y: 0, duration: 3, opacity: 1, ease: Power2.easeInOut })
			.to(slideItem4, { opacity: 1, duration: 5 })
			.fromTo(slideItem5, { y: -60, opacity: 0 }, { y: 0, duration: 3, opacity: 1, ease: Power2.easeInOut })
			.to(slideItem5, { opacity: 1, duration: 5 })
	}, [])

	let specialisationsCount = SPECIALISATIONS.filter((item) => item.description.length > 0).length
	useEffect(() => {
		const gatsbyContainer = document.querySelector('#bg_path')
		console.log('====================================')
		console.log(gatsbyContainer)
		console.log('====================================')
		gatsbyContainer.style.display = 'none'
		// gatsbyContainer[1].style.opacity = '0'
		gatsbyContainer.style.transition = 'opacity 0.3s'
		// gatsbyContainer[1].style.transition = 'opacity 0.3s'
		const showPageTimer = setTimeout(() => {
			gatsbyContainer.style.display = 'block'
		}, 500)
	}, [])
	return (
		<>
			<HeaderWrapper id="top">
				{isIE ? null : (
					<>
						<SvgBottom ref={(el) => (svgbottom = el)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2172.317 270">
							<defs></defs>
							<path
								id="baner_bottom"
								d="M2113,1004.06H-59.316V689.487L1464.7,818.679c8.825.656,17.91.989,27,.989,55.355,0,112.893-12.189,162.015-34.318L2113,571.092v432.967Z"
								transform="translate(59.316 -571.092)"
							/>
						</SvgBottom>
						<SvgBg ref={(el) => (svgbg = el)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1576.033 1145.969">
							<defs></defs>
							<path
								id="bg_path"
								data-name="bg path"
								d="M-362.174,867.84c-32.461-.053-1531.584,0-1576.033,0,.861-1081.675,0-1104.169,0-1145.841l628.031-.128S-1252.069,569.694-362.174,867.84Z"
								transform="translate(1938.207 278.128)"
								opacity="0.4"
							/>
						</SvgBg>
					</>
				)}

				<BackgroundSlider
					initDelay={8} // delay before the first transition (if left at 0, the first image will be skipped initially)
					transition={1} // transition duration between images
					duration={8} // how long an image is shown
					// specify images to include (and their orde
					images={['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg']}
					query={useStaticQuery(graphql`
						{
							backgrounds: allFile(filter: { sourceInstanceName: { eq: "backgrounds" } }) {
								nodes {
									relativePath
									sourceInstanceName
									childImageSharp {
										fluid(maxWidth: 4000, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
					`)}
				>
					<ContainerText>
						<SlideItem ref={(el) => (slideItem1 = el)}></SlideItem>
					</ContainerText>

					{isIE ? null : (
						<ContainerText>
							<SlideItem ref={(el) => (slideItem2 = el)}></SlideItem>
						</ContainerText>
					)}

					{isIE ? null : (
						<ContainerText>
							<SlideItem ref={(el) => (slideItem3 = el)}>
								{/* <HeaderTextGroup long>
                  <div>
                    <h1> Argumenta non numeranda, sed ponderanda sunt </h1>
                    <Line />
                    <h2>Dowodów nie należy liczyć, należy je ważyć</h2>
                  </div>
                </HeaderTextGroup> */}
							</SlideItem>
						</ContainerText>
					)}

					{isIE ? null : (
						<ContainerText>
							<SlideItem ref={(el) => (slideItem4 = el)}>
								<HeaderTextGroup></HeaderTextGroup>
							</SlideItem>
						</ContainerText>
					)}

					{isIE ? null : (
						<ContainerText>
							<SlideItem ref={(el) => (slideItem5 = el)}>
								<HeaderTextGroup></HeaderTextGroup>
							</SlideItem>
						</ContainerText>
					)}
				</BackgroundSlider>
				<BottomMenu ref={(el) => (bottomElements = el)} ie={isIE}>
					<Container>
						<ContactInfo>
							<a href={`tel:${ADDRESS.phone1}`}>
								{
									<div>
										<Phone size={15} /> {ADDRESS.phone1}
									</div>
								}
							</a>
							<a href={`tel:${ADDRESS.phone2}`}>
								{
									<div>
										<Phone size={15} /> {ADDRESS.phone2}
									</div>
								}
							</a>
							<a href={`mailto:${ADDRESS.mail}`}>
								{
									<div>
										<Mail size={15} /> {ADDRESS.mail}
									</div>
								}
							</a>
						</ContactInfo>
					</Container>

					<Line />

					<Blur />
					<Container>
						<BottomFirstElement>
							<p>
								Nasza Kancelaria specjalizuje się między innymi w prawie korporacyjnym (w tym transakcjach M&A), prawie rynku kapitałowego, prawie
								bankowym, prawie nieruchomości <br />i robotach budowlanych, negocjacji umów, prawie energetycznym, prawie karnym, prawie pracy oraz
								prawie zamówień publicznych.
								<AnchorLink to="/#services">czytaj więcej...</AnchorLink>
							</p>
						</BottomFirstElement>
						<CarouselProvider
							infinite={false}
							visibleSlides={3}
							isPlaying={true}
							interval={3000}
							// playDirection={"backward"}
							naturalSlideWidth={300}
							naturalSlideHeight={200}
							totalSlides={SPECIALISATIONS.length}
						>
							<SliderWrapper>
								{SPECIALISATIONS.map((item, index) => {
									return (
										<BottomElement index={index} key={index}>
											<h3>{item.title}</h3>
										</BottomElement>
									)
								})}
							</SliderWrapper>
						</CarouselProvider>
					</Container>
				</BottomMenu>
			</HeaderWrapper>
		</>
	)
}

export default Header
const Blur = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 500px;
	z-index: -100;
	/* background-color: rgba(0, 0, 0, 0.5); */

	/* &&::before {
    content: "";
    filter: blur(4px);
    position: absolute;
    width: 100%;
     height: 100%;
    z-index: -10;
  }
  &&::after {
    top: 40px;
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    backdrop-filter: blur(6px);
    z-index: -11;
  }  */
`

const SvgBottom = styled.svg`
	position: absolute;
	fill: ${(props) => props.theme.color.background};
	/* width: 100%; */
	bottom: 0px;
	z-index: 30;
	/* backdrop-filter: blur(0px); */
`
const SvgBg = styled.svg`
	position: absolute;
	/* width: 100%; */
	height: 100vh;
	left: 0;
	bottom: 0;
	top: 0;
	z-index: 1;

	@media (max-width: ${(props) => props.theme.screen.xs}) {
		left: -60%;
	}
`
const ContactInfo = styled.div`
	display: flex;
	min-height: 20px;
	> div {
		margin-left: 0;
	}

	a {
		color: ${(props) => props.theme.color.white.whiteRegular};
		font-size: ${(props) => props.theme.font.size.xxsmall};
		font-weight: ${(props) => props.theme.font.weight.regular};
		margin-right: 15px;
		min-width: 100px;
		div {
			svg {
				margin-bottom: -2px;
				margin-right: 5px;
				@media (max-width: ${(props) => props.theme.screen.sm}) {
					margin-right: 0px;
				}
			}
		}
		@media (max-width: ${(props) => props.theme.screen.xs}) {
			margin: 0;
		}
	}
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	@media (max-width: ${(props) => props.theme.screen.xs}) {
		flex-direction: column;
		text-align: center;
	}
`
const BottomMenu = styled.div`
	position: absolute;
	bottom: 0;
	max-height: 300px;
	width: 100%;
	width: 100%;
	z-index: 30;

	/* z-index: 30000; */
	> div {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: flex-start;
		margin-top: 15px;
		/* z-index: 30; */
		/* height: 50%; */
	}
	@media (max-width: ${(props) => props.theme.screen.xs}) {
		height: 250px;
	}
`

const SliderWrapper = styled(Slider)`
	/* display: flex; */
	min-width: 1000px;
	align-items: flex-start;
	justify-content: space-between;
`
const BottomFirstElement = styled.div`
	margin-right: 20px;
	min-width: 425px;
	width: 380px;
	/* width: 30%; */
	p {
		z-index: 10;
		font-size: ${(props) => props.theme.font.size.xxxsmall};
		color: ${(props) => props.theme.color.white.whiteRegular};
		font-weight: ${(props) => props.theme.font.weight.regular};
		letter-spacing: 1px;
		/* text-align: left; */
	}
	a {
		color: ${(props) => props.theme.color.white.whiteDark};
		/* background-color: ${(props) => props.theme.color.white.whiteRegular}; */
		padding: 1px 0px 1px 5px;
		border-radius: 10px;
		font-size: ${(props) => props.theme.font.size.xxxsmall};
		width: 100px;
		text-align: center;
		margin-left: auto;
		display: block;
		margin-top: 10px;
		/* text-transform: uppercase; */
		&:hover {
			color: ${(props) => props.theme.color.white.whiteRegular};
			background-color: transparent;
		}
	}
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		display: none;
	}
`
const BottomElement = styled(Slide)`
	z-index: 30;
	flex-basis: 33%;
	margin: 10px 0px;
	/* width: 100%; */
	h3 {
		/* text-align:center; */
		@media (max-width: ${(props) => props.theme.screen.xs}) {
			padding: 0 10px;
		}
	}
	p {
		font-size: ${(props) => props.theme.font.size.xxxsmall};
		color: ${(props) => props.theme.color.white.whiteRegular};
		font-weight: ${(props) => props.theme.font.weight.extraLight};
		letter-spacing: 1px;
		text-align: left;
		@media (max-width: ${(props) => props.theme.screen.xs}) {
			padding: 0 10px;
		}
	}
	@media (max-width: ${(props) => props.theme.screen.xs}) {
		flex-basis: 100%;
		margin: 0px;
	}
`
const HeaderWrapper = styled.header`
	position: relative;
	display: flex;

	background-size: cover;
	background-position: 0 -150px;

	/* max-width: 1400px; */
	/* justify-content: space-between; */
	/* max-width: 100%;
  max-width: 1400px; */
	/* margin: 0 auto; */
	/* background-size: 100% 100%;
  background-position: center top; */
	background-repeat: no-repeat;
	min-height: 100vh;
	overflow: hidden;
	/* align-items: center; */
	z-index: 0;
	&::after {
		content: '';
		background-color: rgba(0, 0, 0, 0.2);
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
	}
	@media (max-width: ${(props) => props.theme.screen.lg}) {
		background-position: 0 -50px;
		background-position: center;
	}
`
const SlideItem = styled.div`
	/* display: none; */
	position: relative;
	text-align: left;
	width: 70%;
	/* max-width: 1400px; */
	/* margin: 0 auto; */
	/* padding: 0 15px; */

	z-index: 10;
	@media (max-width: ${(props) => props.theme.screen.md}) {
		width: 100%;
	}
`
const ContainerText = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	flex-basis: 100%;
	min-height: 100vh;

	margin: 0 auto;
	max-width: 1400px;
	padding: 0;
	z-index: 10;
	padding: 0 15px;
`
const HeaderTextGroup = styled.div`
	position: absolute;
	left: 0%;
	z-index: 100;
	width: 100%;
	text-align: left;
	margin-top: 140px;
	margin-bottom: 50%;

	/* border: 1px solid red; */

	button {
		display: none;
	}
	/* margin-top: 5%; */

	@media (max-width: ${(props) => props.theme.screen.xs}) {
		margin-top: calc(100px + 5%);
		h1 {
			font-size: ${(props) => props.theme.font.size.large};
		}
	}
	@media (max-width: ${(props) => props.theme.screen.md}) {
		width: 100%;
		text-align: center;
		left: 0;
	}
`
