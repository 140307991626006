import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import Logo from "../common/logo";
import gsap, { Power1 } from "gsap";
import { Line } from "../global";
const Overlay = () => {
  let wrapper = useRef(null);
  useEffect(() => {
    gsap.to(wrapper, {
      x: "-100%",
      opacity: 0.8,
      duration: 1.2,
      delay: 1,
      ease: Power1.easeOut,
    });
  }, []);
  return (
    <Wrapper
      ref={(el) => {
        wrapper = el;
      }}>
      <Content>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Title>Kancelaria Prawna</Title>
        <SubTitle>Daniel Krajewski & Maciej Was</SubTitle>
      </Content>
    </Wrapper>
  );
};

export default Overlay;
const Content = styled.div`
  text-align: justify;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled.div`
  text-align: justify;
  letter-spacing: 1.5px;
  color: ${(props) => props.theme.color.black.black};
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: ${(props) => props.theme.font.size.big};
`;
const SubTitle = styled.div`
  position: relative;
  text-align: justify;
  letter-spacing: 1.5px;
  color: ${(props) => props.theme.color.black.black};
  /* border-bottom: 1px solid ${(props) => props.theme.color.primary}; ; */
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.color.primary};
    z-index: 100;
    margin-top: 30px;
  }
`;
const LogoWrapper = styled.div`
  margin-bottom: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  /* border: 1px solid red; */
`;
const Wrapper = styled.div`
  position: fixed;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* opacity: 0.98; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20000000;
`;
