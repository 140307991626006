//theme default

const theme = {
  font: {
    size: {
      xxxxsmall: "9px",
      xxxsmall: "11px",
      xxsmall: "13px;",
      xsmall: "16px;",
      small: "17px;",
      regular: "19px",
      big: "28px;",
      extraBig: "35px;",
      large: "40px;",
      larger: "50px",
      extralarge: "55px",
    },
    weight: {
      thin: 100,
      extraLight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
  },
  color: {
    primary: "#494949",
    secondary: "#4E4E4E",
    accent: "#14293e",
    background: "#fafafa",
    backgroundDark: "#1d1d1d",
    backgroundDarker: "#161616",
    white: {
      transparent: "rgba(255, 255, 255, 0.9)",
      transparent2: "rgba(255, 255, 255, 0.85)",
      transparentLight: "rgba(255, 255, 255, 0.3)",
      whiteRegular: "#ffffff",
      whiteDark: "#dddddd",
      whiteDarker: "#9C9C9C",
    },
    black: {
      transparent: "rgba(0, 0,0, 0.35)",
      transparentLight: "rgba(250,250,250, 0.8)",
      transparentDark: "rgba(0,0,0, 0.95)",
      transparentMedium: "rgba(0,0,0, 0.70)",
      silver: "#c9c9c9",
      black: "000",
      grey: "#222A2A",
      greyDark: "#141414",
      greyDarker: "#131313",
    },
  },
  transition: {
    primary: "0.5s cubic-bezier(0.3, 0.8, 0.2, 1)",
    secondary: "1s cubic-bezier(0.3, 0.8, 0.2, 1)",
  },
  other: {
    navigationHeight: "100px",
  },
  screen: {
    xs: "575px",
    // sm: "767px",
    sm: "844px",
    md: "991px",
    lg: "1280px",
    xl: "1450px",
  },
};

export default theme;
